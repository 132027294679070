//Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg p-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between text-center md:text-left">

        <div className="mb-4 md:mb-0">
          <h2 className="text-lg font-semibold text-gray-800">Service de Traduction</h2>
          <p className="text-gray-600">Votre partenaire de confiance pour des traductions de qualité.</p>
          <p className="text-gray-600">Adresse : 10 Avenue Jean-Baptiste Greuze 21000 Dijon </p>
          <p className="text-gray-600">Numéro SIRET : 83091566600010 </p>
          <p className="text-gray-600">Numéro RCS	Dijon : A 830 915 666</p>
        </div>

        <div className="mb-4 md:mb-0">
          <h3 className="text-md font-semibold text-gray-800">Liens Utiles</h3>
          <ul className="space-y-1">
            <li>
              <Link to="/" className="text-gray-700 hover:text-slate-400 transition-colors duration-300">Accueil</Link>
            </li>
            <li>
              <Link to="/contact" className="text-gray-700 hover:text-slate-400 transition-colors duration-300">Contact</Link>
            </li>
            <li>
              <Link to="/upload" className="text-gray-700 hover:text-slate-400 transition-colors duration-300">Envoyer une Commande</Link>
            </li>
            <li>
              <Link to="/orders" className="text-gray-700 hover:text-slate-400 transition-colors duration-300">Mes Commandes</Link>
            </li>
          </ul>
        </div>

        <div className="mb-4 md:mb-0">
          <h3 className="text-md font-semibold text-gray-800">Contactez-nous</h3>
          <p className="text-gray-600">Email : contact@salhi-traduction.fr</p>
          <p className="text-gray-600">Téléphone : +33 6 69 15 12 16</p>
        </div>
        <div>
          <h3 className="text-md font-semibold text-gray-800">Suivez-nous</h3>
          <ul className="flex space-x-4 mt-2">
            <li>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700 hover:text-slate-400 transition-colors duration-300"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700 hover:text-slate-400 transition-colors duration-300"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700 hover:text-slate-400 transition-colors duration-300"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center mt-6">
        <p className="text-gray-700">
          &copy; {new Date().getFullYear()} Service de Traduction. Tous droits réservés.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
