// src/axiosConfig.js
import axios from 'axios';

axios.defaults.withCredentials = true;

// Créer une instance d'axios avec la configuration de l'URL de base
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default instance;
