import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import RegisterForm from '../components/RegisterForm';
import LoginForm from '../components/LoginForm';
import Navbar from '../components/Navbar';


const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setErrorMessage('');
  };

  return (
    <>
      <Navbar />
      <div className="auth-container min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 p-6">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-4">{isLogin ? 'Connexion' : 'Inscription'}</h1>
          {errorMessage && <p className="error-message text-red-500" role="alert">{errorMessage}</p>}
          {isLogin ? <LoginForm setErrorMessage={setErrorMessage} /> : <RegisterForm setErrorMessage={setErrorMessage} />}
          <button
            onClick={toggleForm}
            className="w-full mt-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
            aria-live="polite"
            aria-expanded={!isLogin}
          >
            {isLogin ? 'Créer un compte' : 'Déjà un compte ? Se connecter'}
          </button>
          <p className="text-center mt-4">
            <Link to="/" className="text-blue-600 hover:underline">Retour à l'accueil</Link>
          </p>
        </div>
      </div>

    </>
  );
};

export default Auth;
