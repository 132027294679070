import React, { useState } from 'react'; 
import PropTypes from 'prop-types';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie'; // Importation de js-cookie

const LoginForm = ({ setErrorMessage, onClose }) => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, password }, { withCredentials: true });

      if (response.data && response.data.token) {
        // Stocker le token dans les cookies
        Cookies.set('token', response.data.token, { expires: 1 }); // Le token expire après 7 jours
        login(); // Appelle la fonction login pour mettre à jour le contexte utilisateur
        setMessage('Connexion réussie');
        setErrorMessage('');
        setEmail('');
        setPassword('');
        navigate('/orders');
        onClose();
      } else {
        setErrorMessage('Token non trouvé dans la réponse.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Erreur lors de la connexion');
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      {loading && <p>Chargement...</p>}
      {message && <p>{message}</p>} 
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
            className="border border-gray-300 p-2 rounded w-full mt-1"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="password">Mot de passe</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
            className="border border-gray-300 p-2 rounded w-full mt-1"
          />
        </div>
        <button type="submit" disabled={loading} className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
          Se connecter
        </button>
      </form>
      <p className="text-center mt-4">
        <button className="cursor-pointer" onClick={onClose}>Fermer</button>
      </p>
    </div>
  );
};

LoginForm.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LoginForm;
