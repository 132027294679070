import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Profile = () => {
  const { isValidToken, logout } = useAuth();
  const navigate = useNavigate();

  const handleServiceClick = (service) => {
    navigate('/QuoteForm', { state: { service } });
  };

  const handleLogout = () => {
    logout();
    navigate('/auth');
  };

  return (
    <div className="pt-5 pb-5 bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 min-h-screen flex items-center justify-center">
<div className="pt-5 fixed top-0 left-0 w-full bg-white bg-opacity-0 backdrop-filter backdrop-blur-lg z-40 hidden md:flex">
  <div className="max-w-3xl mx-auto flex justify-between">
    <Link to="/" className="text-white hover:text-slate-300 text-xl text-shadow" style={{ textShadow: '5px 5px 5px #000' }}>Accueil</Link>
    <Link to="/contact" className="text-white hover:text-slate-300 text-xl contrast-125" style={{ textShadow: '5px 5px 5px #000' }}>Contact</Link>
    <Link to="/upload" className="text-white hover:text-slate-300 text-xl contrast-125" style={{ textShadow: '5px 5px 5px #000' }}>Envoyer une commande</Link>
    <Link to="/orders" className="text-white hover:text-slate-300 text-xl contrast-125" style={{ textShadow: '5px 5px 5px #000' }}>Mes Commandes</Link>
    {isValidToken ? (
      <Link to="#" onClick={handleLogout} className="text-white hover:text-slate-300 text-xl contrast-125" style={{ textShadow: '5px 5px 5px #000' }}>
        Déconnexion
      </Link>
    ) : (
      <Link to="/auth" className="text-white hover:text-slate-300 text-xl contrast-125" style={{ textShadow: '5px 5px 5px #000' }}>
        Connexion / Inscription
      </Link>
    )}
  </div>
</div>


      <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8 mt-20">
        <header className="text-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800 mt-4">SALHI AICHA</h1>
          <h2 className="text-xl font-medium text-gray-500">Traductrice Professionnelle Assermentée</h2>
          <h2 className="text-xl font-medium text-gray-500">Spécialiste en Arabe et Français</h2>
        </header>

        <section className="text-center mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">À propos de moi</h3>
          <p className="text-gray-600 leading-relaxed">
            Je suis traductrice certifiée en arabe et en français, diplômée de l'ITIRI (Université de Strasbourg) et assermentée près de la Cour d'Appel de Dijon. 
            Spécialisée dans les domaines juridique et administratif, j'ai acquis plusieurs années d'expérience et une solide expertise en traduction de documents tels que contrats, 
            actes notariés et certificats.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Mes Services</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <button
              onClick={() => handleServiceClick('Traduction Juridique')}
              className="bg-gray-100 p-4 rounded-lg text-center text-gray-700 cursor-pointer hover:bg-gray-200 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Traduction Juridique
            </button>
            <button
              onClick={() => handleServiceClick('Traduction Administrative')}
              className="bg-gray-100 p-4 rounded-lg text-center text-gray-700 cursor-pointer hover:bg-gray-200 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Traduction Administrative
            </button>
            <button
              onClick={() => handleServiceClick('Légalisation de Documents')}
              className="bg-gray-100 p-4 rounded-lg text-center text-gray-700 cursor-pointer hover:bg-gray-200 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Légalisation de Documents
            </button>
            <button
              onClick={() => handleServiceClick('Révisions et Corrections')}
              className="bg-gray-100 p-4 rounded-lg text-center text-gray-700 cursor-pointer hover:bg-gray-200 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Révisions et Corrections
            </button>
          </div>
        </section>
        <section className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Engagement envers la Qualité</h3>
          <p className="text-gray-600 leading-relaxed">
            Je m’engage à fournir des traductions précises et conformes, avec une attention particulière aux détails. Chaque projet est traité avec le plus grand soin pour garantir la conformité avec les exigences légales.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Confidentialité</h3>
          <p className="text-gray-600 leading-relaxed">
            La confidentialité de vos informations est une priorité. Je m'engage à traiter tous les documents avec la plus grande discrétion. Aucune information ne sera partagée sans votre consentement explicite.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Tarification et Délais</h3>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <p className="text-gray-600 mb-2">
              <span className="font-bold">Tarif :</span> 25 euros par page.
            </p>
            <p className="text-gray-600 mb-2">
              <span className="font-bold">Volume de Traduction :</span> 4 à 6 pages par jour.
            </p>
            <p className="text-gray-600 mb-2">
              <span className="font-bold">Devis :</span> Réponse en 2 à 3 heures.
            </p>
            <p className="text-gray-600 mb-2">
              <span className="font-bold">Délais de Traduction :</span> 2 jours pour 1 à 3 pages.
            </p>
          </div>

          <div className="mt-6 text-center">
            <Link 
              to="/upload" 
              className="inline-block bg-blue-600 text-white text-xl font-bold py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
            >
              Envoyer une commande
            </Link>
          </div>
        </section>

        <section className="text-center">
          <Link to="/contact" className="text-2xl font-semibold text-center text-gray-700 mb-4">Contact</Link>
          <p className="text-gray-600">
            Pour toute demande de renseignements, vous pouvez me contacter par e-mail à <a href="mailto:contact@salhi-traduction.fr" className="text-blue-600 hover:underline"> contact@salhi-traduction.fr
</a>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Profile;
