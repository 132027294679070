import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isTokenValid } from '../utils/authUtils';

const AdminControl = () => {
  const [orders, setOrders] = useState([]);
  const [messages, setMessages] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); 

  useEffect(() => {
    const checkUserRole = () => {
      const token = localStorage.getItem('token');
      const { isValid, role } = isTokenValid(token); 

      if (isValid && role === 'admin') {
        setIsAdmin(true);
        fetchOrders();
        fetchMessages();
        fetchQuotes();
      } else {
        setIsAdmin(false);
      }
    };

    checkUserRole();
  }, []);

  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes:', error);
    }
  };

  const handleAccept = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/orders/${orderId}/accept`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchOrders(); 
      setMessage('Commande acceptée avec succès.');
    } catch (error) {
      setMessage('Erreur lors de l’acceptation de la commande.');
      console.error(error);
    }
  };

  const handleRefuse = async (orderId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/orders/${orderId}/refuse`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchOrders(); 
      setMessage('Commande refusée avec succès.');
    } catch (error) {
      setMessage('Erreur lors du refus de la commande.');
      console.error(error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/users/${userId}/message`, { message: userMessage }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage('Message envoyé avec succès.');
      setUserId('');
      setUserMessage('');
    } catch (error) {
      setMessage('Erreur lors de l’envoi du message.');
      console.error(error);
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/messages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des messages:', error);
    }
  };

  const fetchQuotes = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/quotes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuotes(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des devis:', error);
    }
  };

  if (!isAdmin) {
    return <p>Vous n'avez pas accès à cette page.</p>;
  }

  return (
    <div className="min-h-screen p-6 bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Contrôle Administratif</h1>
        
        {message && <p className="text-green-600 mb-4">{message}</p>}
        
        <h2 className="text-2xl font-semibold mb-4">Liste des Commandes</h2>
        <div className="overflow-x-auto mb-6">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-3 border-b text-left text-gray-600">ID</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Utilisateur</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Fichier</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Statut</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order._id} className="hover:bg-gray-50">
                  <td className="py-2 px-3 border-b">{order._id}</td>
                  <td className="py-2 px-3 border-b">{order.email}</td>
                  <td className="py-2 px-3 border-b">{order.filePath}</td>
                  <td className="py-2 px-3 border-b">{order.status}</td>
                  <td className="py-2 px-3 border-b">
                    <button
                      onClick={() => handleAccept(order._id)}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                    >
                      Accepter
                    </button>
                    <button
                      onClick={() => handleRefuse(order._id)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 ml-2"
                    >
                      Refuser
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Liste des Messages</h2>
        <div className="overflow-x-auto mb-6">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-3 border-b text-left text-gray-600">ID</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Utilisateur</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Message</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">IP Address</th>
              </tr>
            </thead>
            <tbody>
              {messages.map(message => (
                <tr key={message._id} className="hover:bg-gray-50">
                  <td className="py-2 px-3 border-b">{message._id}</td>
                  <td className="py-2 px-3 border-b">{message.email}</td>
                  <td className="py-2 px-3 border-b">{message.message}</td>
                  <td className="py-2 px-3 border-b">{message.ipAddress}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Liste des devis</h2>
        <div className="overflow-x-auto mb-6">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-3 border-b text-left text-gray-600">ID</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Utilisateur</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Titre</th>
                <th className="py-2 px-3 border-b text-left text-gray-600">Message</th>
              </tr>
            </thead>
            <tbody>
              {quotes.map(quote => (
                <tr key={quote._id} className="hover:bg-gray-50">
                  <td className="py-2 px-3 border-b">{quote._id}</td>
                  <td className="py-2 px-3 border-b">{quote.email}</td>
                  <td className="py-2 px-3 border-b">{quote.title}</td>
                  <td className="py-2 px-3 border-b">{quote.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Envoyer un Message à un Utilisateur</h2>
        <form onSubmit={handleSendMessage} className="mb-6">
          <div className="mb-4">
            <label htmlFor="userId" className="block text-gray-700 font-semibold mb-1">ID de l'Utilisateur :</label>
            <input
              type="text"
              id="userId"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="userMessage" className="block text-gray-700 font-semibold mb-1">Message :</label>
            <textarea
              id="userMessage"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Envoyer Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminControl;
