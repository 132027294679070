import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../components/Navbar';

const UploadOrder = () => {
  const [setUserInfo] = useState({});
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [sourceLanguage, setSourceLanguage] = useState('fr');
  const [targetLanguage, setTargetLanguage] = useState('ar');
  const [certified, setCertified] = useState(false);
  const [legalization, setLegalization] = useState('none');
  const [numberOfLegalizations, setNumberOfLegalizations] = useState(1);
  const [deliveryMethod, setDeliveryMethod] = useState('email');
  const [postalType, setPostalType] = useState('');
  const [destinationCountry, setDestinationCountry] = useState('');
  const [message, setMessage] = useState('');
  const [price, setPrice] = useState(0);
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('standard');
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [cityError, setCityError] = useState('');
  const [countryError, setCountryError] = useState('');
const fetchUserInfo = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    setLoading(false);
    setError('Vous devez être connecté pour voir vos informations.');
    return;
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setUserInfo(response.data);
    setEmail(response.data.email || '');

  } catch (err) {
    setError('Erreur lors de la récupération des informations utilisateur.');
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  fetchUserInfo();
}, []);


  const PRICES = useMemo(() => ({
    translation: 25,
    legalization: {
      mairie: 20,
      ministere: 50,
    },
    delivery: {
      email: 0,
      postal: {
        ordinaire: 5,
        prioritaire: 10,
        recommandee: 15,
        dhl: 80,
      },
    },
    deliveryTime: {
      standard: 0, // 72 heures
      urgent: 10,   // 48 heures
      very_urgent: 20, // 24 heures
    },
  }), []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const acceptedMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png',
      'image/gif',
    ];

    if (selectedFile && acceptedMimeTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setError('');
    } else {
      setFile(null);
      setError('Veuillez sélectionner un fichier valide (PDF, Word, ou image).');
    }
  };


  const calculatePrice = useCallback((deliveryMethod, postalType, deliveryTime) => {
    let calculatedPrice = PRICES.translation * numberOfPages;

    if (legalization !== 'none') {
      const legalizationPrice = PRICES.legalization[legalization] || 0;
      calculatedPrice += legalizationPrice * numberOfLegalizations;
    }

    if (deliveryMethod === 'postal') {
      calculatedPrice += PRICES.delivery.postal[postalType] || 0;
    } else {
      calculatedPrice += PRICES.delivery[deliveryMethod] || 0;
    }

    const deliveryTimePrice = PRICES.deliveryTime[deliveryTime] || 0;
    calculatedPrice += deliveryTimePrice;

    setPrice(calculatedPrice);
  }, [legalization, numberOfPages, numberOfLegalizations, PRICES]);

  useEffect(() => {
    calculatePrice(deliveryMethod, postalType, deliveryTime);
  }, [sourceLanguage, targetLanguage, certified, legalization, numberOfLegalizations, deliveryMethod, postalType, destinationCountry, deliveryTime, numberOfPages, calculatePrice]);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    if (e.target.name === "sourceLanguage") {
      setSourceLanguage(selectedLanguage);

      setTargetLanguage(selectedLanguage === 'fr' ? 'ar' : 'fr');
    } else {
      setTargetLanguage(selectedLanguage);
      setSourceLanguage(selectedLanguage === 'fr' ? 'ar' : 'fr');
    }
  };

  const handleDeliveryMethodChange = (e) => {
    const selectedDeliveryMethod = e.target.value;
    setDeliveryMethod(selectedDeliveryMethod);

    if (selectedDeliveryMethod === 'postal') {
      const defaultPostalType = 'ordinaire'; 
      setPostalType(defaultPostalType); 
    } else {
      setPostalType(''); 
    }
  };

  const handlePostalTypeChange = (e) => {
    const selectedPostalType = e.target.value;
    setPostalType(selectedPostalType);
    calculatePrice(deliveryMethod, selectedPostalType);
  };

const validatePostalFields = () => {
    let isValid = true;

    setNameError('');
    setStreetError('');
    setPostalCodeError('');
    setCityError('');
    setCountryError('');

    if (deliveryMethod === 'postal') {
      if (!name) {
        setNameError('Le nom est requis.');
        isValid = false;
      }
      if (!street) {
        setStreetError('Numéro et nom de la rue sont requis.');
        isValid = false;
      }
      if (!postalCode) {
        setPostalCodeError('Le code postal est requis.');
        isValid = false;
      }
      if (!city) {
        setCityError('La ville est requise.');
        isValid = false;
      }
      if (!country) {
        setCountryError('Le pays est requis.');
        isValid = false;
      }
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const isPostalFieldsValid = validatePostalFields();
    if (!isPostalFieldsValid) {
      setMessage('Veuillez remplir tous les champs obligatoires.');
      return; 
    }


    let hasError = false;


if (!file) {
      setMessage('Veuillez sélectionner un fichier.');
      hasError = true;
    } else if (!sourceLanguage) {
      setMessage('Veuillez sélectionner une langue source.');
      hasError = true;
    } else if (!targetLanguage) {
      setMessage('Veuillez sélectionner une langue cible.');
      hasError = true;
    } else if (!name) {
      setMessage('Veuillez entrer votre nom complet.');
      hasError = true;
    } else if (!email) {
      setMessage('Veuillez entrer une adresse email valide.');
      hasError = true;
    }

    if (hasError) {
        return;
    }

    if (deliveryMethod === 'postal' && (!postalType || postalType === '')) {
        setMessage('Veuillez sélectionner un type postal valide.');
        return;
    }
    const combinedAddress = deliveryMethod === 'postal'
        ? `${name}, ${street}, ${postalCode}, ${city}, ${country}`
        : '';

    const orderDetails = {
        file,
        sourceLanguage,
        targetLanguage,
        certified,
        legalization,
        numberOfLegalizations,
        deliveryMethod,
        postalType: deliveryMethod === 'postal' ? postalType : null,
        destinationCountry,
        price,
        address: combinedAddress,
        deliveryTime,
        numberOfPages,
        name,
        email,
    };

    navigate('/checkout', { state: { price, orderDetails } });
};
const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setMessage('');
};


  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 p-6">
        <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8 sm:w-full lg:w-3/5 xl:w-1/2">
          <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Envoyer une commande</h1>
                    {error && <div className="text-red-600">{error}</div>}

          {message && <p className="text-red-500">{message}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="file">Fichier</label>
              <input type="file" accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif" onChange={handleFileChange} className="w-full p-2 border rounded"  />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Nom complet</label>
              <input
                type="text"
                value={name}
                onChange={handleChange(setName)}
                required
                className="w-full p-2 border rounded"
              />
              {nameError && <div className="text-red-500 text-sm">{nameError}</div>}
            </div>


            <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={handleChange(setEmail)}
              required
              className="w-full p-2 border rounded"
            />
            </div>




            <div className="mb-4">
              <label className="block mb-2">Délai de livraison</label>
              <select value={deliveryTime} onChange={(e) => setDeliveryTime(e.target.value)} className="w-full p-2 border rounded">
                <option value="standard">72 heures</option>
                <option value="urgent">Urgent (48 heures) +10€</option>
                <option value="very_urgent">Très Urgent (24 heures) +20€</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Langue source</label>
              <select name="sourceLanguage" value={sourceLanguage} onChange={handleLanguageChange} className="w-full p-2 border rounded">
                <option value="fr">Français</option>
                <option value="ar">Arabe</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Langue cible</label>
              <select name="targetLanguage" value={targetLanguage} onChange={handleLanguageChange} className="w-full p-2 border rounded">
                <option value="fr">Français</option>
                <option value="ar">Arabe</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Méthode de livraison</label>
              <select value={deliveryMethod} onChange={handleDeliveryMethodChange} className="w-full p-2 border rounded">
                <option value="email">Email</option>
                <option value="postal">Livraison postale</option>
              </select>
            </div>

            {deliveryMethod === 'postal' && (
              <>
                <div className="mb-4">
                  <label className="block mb-2">Type de livraison postale</label>
                  <select value={postalType} onChange={handlePostalTypeChange} className="w-full p-2 border rounded">
                    <option value="ordinaire">Ordinaire (5€)</option>
                    <option value="prioritaire">Prioritaire (10€)</option>
                    <option value="recommandee">Recommandée (15€)</option>
                    <option value="dhl">DHL (80€)</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Adresse</label>
                  <input
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    placeholder="Numéro et nom de la rue"
                    required
                    className="w-full p-2 border rounded"
                  />
                  {streetError && <p className="text-red-500 text-sm">{streetError}</p>}
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    placeholder="Code postal"
                    required
                    className="w-full p-2 border rounded"
                  />
                  {postalCodeError && <p className="text-red-500 text-sm">{postalCodeError}</p>}
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Ville"
                    required
                    className="w-full p-2 border rounded"
                  />
                  {cityError && <p className="text-red-500 text-sm">{cityError}</p>}
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Pays"
                    required
                    className="w-full p-2 border rounded"
                  />
                  {countryError && <p className="text-red-500 text-sm">{countryError}</p>}
                </div>
              </>
            )}

            <div className="mb-4">
              <label className="block mb-2">Certifié</label>
              <input type="checkbox" checked={certified} onChange={() => setCertified(!certified)} />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Légalisation</label>
              <select value={legalization} onChange={(e) => setLegalization(e.target.value)} className="w-full p-2 border rounded">
                <option value="none">Pas de légalisation</option>
                <option value="mairie">Mairie (20€ par légalisation)</option>
                <option value="ministere">Ministère (50€ par légalisation)</option>
              </select>
            </div>

            {legalization !== 'none' && (
              <div className="mb-4">
                <label className="block mb-2">Nombre de légalisations</label>
                <input
                  type="number"
                  value={numberOfLegalizations}
                  onChange={(e) => setNumberOfLegalizations(e.target.value)}
                  min="1"
                  className="w-full p-2 border rounded"
                />
              </div>
            )}

            <div className="mb-4">
              <label className="block mb-2">Nombre de pages</label>
              <input
                type="number"
                value={numberOfPages}
                onChange={(e) => setNumberOfPages(e.target.value)}
                min="1"
                required
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Prix total</label>
              <p className="text-xl font-bold">{price}€</p>
            </div>

            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Poursuivre vers le paiement
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadOrder;
