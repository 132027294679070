import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    language: '',
    country: '',
    legalizationType: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    alert('Votre message a été envoyé !');


    setFormData({
      name: '',
      email: '',
      language: '',
      country: '',
      legalizationType: '',
      message: '',
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-6">
      <div>
        <label htmlFor="name" className="block text-gray-700">Nom :</label>
        <input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full border border-gray-300 rounded p-2"
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-gray-700">Email :</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full border border-gray-300 rounded p-2"
        />
      </div>
    
      
      <div>
        <label htmlFor="country" className="block text-gray-700">Pays de Légalisation :</label>
        <input
          type="text"
          name="country"
          value={formData.country}
          onChange={handleChange}
          required
          className="w-full border border-gray-300 rounded p-2"
        />
      </div>
      <div>
        <label htmlFor="legalizationType" className="block text-gray-700">Type de Légalisation :</label>
        <select
          name="legalizationType"
          value={formData.legalizationType}
          onChange={handleChange}
          required
          className="w-full border border-gray-300 rounded p-2"
        >
          <option value="">Sélectionnez un type de légalisation</option>
          <option value="document">Document Administratif</option>
          <option value="certificat">Certificat Commercial</option>
        </select>
      </div>
      <div>
        <label htmlFor="message" className="block text-gray-700">Message :</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className="w-full border border-gray-300 rounded p-2"
          rows="4"
        />
      </div>
      <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition">
        Envoyer
      </button>
    </form>
  );
};

export default ContactForm;
