// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);

  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);

  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 p-4 shadow-lg z-50">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <p className="text-gray-700">
          Nous utilisons des cookies pour améliorer votre expérience. Acceptez-vous l'utilisation des cookies ?
        </p>
        <div className="mt-4 md:mt-0 md:flex md:space-x-4">
          <button
            onClick={handleAccept}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
          >
            Accepter
          </button>
          <button
            onClick={handleReject}
            className="border border-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-100 transition"
          >
            Refuser
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
