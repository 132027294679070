// src/App.js
import React from 'react';
import './index.css';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import UploadOrder from './pages/UploadOrder';
import Auth from './pages/Auth'; 
import Orders from './pages/Orders';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminControl from './pages/AdminControl'; 
import Navbar from './components/Navbar';
import Legalisation from './pages/Legalisation'; 
import CookieConsent from './components/CookieConsent';
import OrderConfirmation from './pages/OrderConfirmation';
import QuoteForm from './components/QuoteForm';
import OrderDetail from './pages/OrderDetail';
import Checkout from './pages/Checkout'; 
import { AuthProvider } from './context/AuthContext';

axios.defaults.withCredentials = true;

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <CookieConsent />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/upload" element={<UploadOrder />} />
            <Route path="/admin" element={<AdminControl />} /> 
            <Route path="/auth" element={<Auth />} />
            <Route path="/Legalisation" element={<Legalisation />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/Navbar" element={<Navbar />} />
            <Route path="/QuoteForm" element={<QuoteForm />} />
            <Route path="/order/:id" element={<OrderDetail />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order-confirmation" element={<OrderConfirmation />} />
          </Routes>
        </main>
        <Footer />
      </AuthProvider>
    </Router>
  );
};

export default App;
