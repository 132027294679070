import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Cookies from 'js-cookie'; // Importation de js-cookie

const OrderDetail = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchOrderDetails = async () => {
    const token = Cookies.get('token'); // Lire le token depuis les cookies
    if (!token) {
      setLoading(false);
      setError('Vous devez être connecté pour voir les détails de la commande.');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/orders/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      });
      setOrder(response.data);
    } catch (err) {
      setError('Erreur lors de la récupération des détails de la commande.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  return (
    <>
      <Navbar />
      <div className="min-h-screen p-6 bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8 min-h-screen">
          {loading && <p>Chargement des détails de la commande...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {order && (
            <>
              <h1 className="text-3xl font-bold text-gray-800 mb-4">Détails de la Commande</h1>
              <p><span className="font-bold">Commande ID :</span> {order.orderNumber}</p>
              <p><span className="font-bold">Statut :</span> {order.status}</p>
              <p><span className="font-bold">Date :</span> {new Date(order.createdAt).toLocaleDateString()}</p>
              <p><span className="font-bold">Description :</span> {order.description || 'Aucune description fournie.'}</p>
              <Link to="/orders" className="text-blue-500 hover:underline mt-4 block">Retourner à Mes Commandes</Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
