// src/components/ContactForm.js
import React, { useState } from 'react';
const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, { name, email, message });
    

      setFeedback('Message envoyé avec succès');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setFeedback(error.response.data.message || 'Vous avez atteint la limite de messages pour aujourd\'hui.');
      } else {
        setFeedback('Erreur lors de l’envoi du message');
      }
    }
  };

  return (
    
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-gray-700">Nom</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-gray-700">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-gray-700">Message</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
          rows="4"
        />
      </div>
      <button
        type="submit"
        className="w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-500 transition duration-300"
      >
        Envoyer
      </button>
      {feedback && <p className="text-center text-red-500">{feedback}</p>}
    </form>
  );
};

export default ContactForm;
