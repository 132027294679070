// CheckoutForm.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({ orderDetails, navigate }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {

            },
            redirect: 'if_required',
        });

        if (error) {
            setMessage(error.message);
            setIsLoading(false);
            return;
        }

        // Paiement réussi
        if (paymentIntent.status === "succeeded") {
            setMessage("Paiement réussi !");

            const formData = new FormData();
            formData.append('file', orderDetails.file);
            formData.append('sourceLanguage', orderDetails.sourceLanguage);
            formData.append('targetLanguage', orderDetails.targetLanguage);
            formData.append('certified', orderDetails.certified);
            formData.append('legalization', orderDetails.legalization);
            formData.append('numberOfLegalizations', orderDetails.numberOfLegalizations);
            formData.append('deliveryMethod', orderDetails.deliveryMethod);

            if (orderDetails.deliveryMethod === 'postal') {
                formData.append('postalType', orderDetails.postalType);
            }

            formData.append('destinationCountry', orderDetails.destinationCountry);
            formData.append('address', orderDetails.address);
            formData.append('deliveryTime', orderDetails.deliveryTime);
            formData.append('numberOfPages', orderDetails.numberOfPages);
            formData.append('name', orderDetails.name);
            formData.append('email', orderDetails.email);
            formData.append('price', paymentIntent.amount / 100);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/upload`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                const data = await response.json();

                if (response.ok) {

                    alert('Commande confirmée et envoyée !');
                    navigate('/order-confirmation', { state: { orderNumber: data.order.orderNumber } });
                } else {
                    setMessage("Erreur lors de la création de la commande : " + (data.error || "Une erreur est survenue."));
                }
            } catch (error) {
                console.error("Erreur lors de l'enregistrement de la commande :", error);
                setMessage("Erreur lors de l'enregistrement de la commande.");
            }
        } else {
            setMessage("Le paiement a échoué. Veuillez réessayer.");
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Payer"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

CheckoutForm.propTypes = {
    orderDetails: PropTypes.shape({
        sourceLanguage: PropTypes.string.isRequired,
        targetLanguage: PropTypes.string.isRequired,
        certified: PropTypes.bool.isRequired,
        legalization: PropTypes.string.isRequired,
        numberOfLegalizations: PropTypes.number.isRequired,
        deliveryMethod: PropTypes.string.isRequired,
        postalType: PropTypes.string,
        destinationCountry: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        deliveryTime: PropTypes.string.isRequired,
        numberOfPages: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        file: PropTypes.string,
    }).isRequired,
    navigate: PropTypes.func.isRequired,
};
