// utils/authUtils.js
const base64UrlDecode = (str) => {
  const padding = '='.repeat((4 - str.length % 4) % 4); // Ajoute le padding nécessaire
  const base64 = (str + padding).replace(/-/g, '+').replace(/_/g, '/'); // Remplace les caractères URL-safe
  return atob(base64); // Décode le Base64
};

export const isTokenValid = (token) => {
  console.log(token)
  if (!token) {
    console.warn("Token not found."); // Avertir si aucun token n'est fourni
    return { isValid: false, role: null }; 
  }

  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) {
    console.error('Invalid token format: Token must consist of three parts.'); // Erreur de format
    return { isValid: false, role: null };
  }

  try {
    const payloadBase64 = tokenParts[1]; // Récupérer la partie payload du token
    const decodedPayload = JSON.parse(base64UrlDecode(payloadBase64)); // Décode le payload

    if (decodedPayload.exp) {
      const isExpired = decodedPayload.exp * 1000 < Date.now(); // Vérifier si le token est expiré
      const userRole = decodedPayload.role || null; // Récupérer le rôle de l'utilisateur
      return { isValid: !isExpired, role: userRole }; // Retourner l'état de validité et le rôle
    } else {
      console.error('Expiration time (exp) not found in token payload.'); // Absence de la date d'expiration
      return { isValid: false, role: null };
    }
  } catch (error) {
    console.error("Error validating token:", error); // Gérer les erreurs de validation
    return { isValid: false, role: null };
  }
};
