import React, { useState } from 'react'; 
import PropTypes from 'prop-types';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const RegisterForm = ({ setErrorMessage }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, { 
        username, 
        email, 
        password, 
        address, 
        phone
      });
      setMessage('Inscription réussie. Vous pouvez maintenant vous connecter.');
      setErrorMessage('');
      navigate('/orders');
    } catch (error) {
      console.error(error.response.data);
      setErrorMessage('Erreur lors de l’inscription');
      setMessage('');
    }
  };
  return (
    <div>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Nom d'utilisateur</label>
          <input 
            type="text" 
            id="username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label htmlFor="password">Mot de passe</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label htmlFor="address">Adresse</label>
          <input 
            type="text" 
            id="address" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)} 
          />
        </div>
        <div>
          <label htmlFor="phone">Téléphone</label>
          <input 
            type="tel" 
            id="phone" 
            value={phone} 
            onChange={(e) => setPhone(e.target.value)} 
          />
        </div>
        <button type="submit">S'inscrire</button>
      </form>
    </div>
  );
};

RegisterForm.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
};

export default RegisterForm;
