import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useAuth } from '../context/AuthContext'; // Importer le contexte d'authentification
import Cookies from 'js-cookie'; // Importer la bibliothèque js-cookie

const Orders = () => {
  const { isValidToken } = useAuth(); // Utilisation du contexte pour vérifier le token
  const [orders, setOrders] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchUserInfo = async () => {
    const token = Cookies.get('token');
    console.log("tooooooooooooken " + token)
    if (!token) {
      setLoading(false);
      setError('Vous devez être connecté pour voir vos informations.');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      });
      setUserInfo(response.data);
    } catch (err) {
      setError('Erreur lors de la récupération des informations utilisateur.');
    }
  };

  const fetchOrders = async () => {
    const token = Cookies.get('token');
    if (!token) {
      setLoading(false);
      setError('Vous devez être connecté pour voir vos commandes.');
      return;
    }

try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/orders`, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true, // Important pour envoyer les cookies
    });
    setOrders(response.data.orders || []);
  } catch (err) {
        console.error(err); // Log pour le débogage

    setError('Erreur lors de la récupération des commandes.');
  }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserInfo();
      await fetchOrders();
      setLoading(false);
    };
    fetchData();
  }, [isValidToken]);

return (
  <>
    <Navbar />
    <div className="min-h-screen p-6 bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600">
      
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6 min-h-screen">
        <h1 className="py-5 text-3xl font-bold text-gray-800 mb-4 text-center">Mes Commandes</h1>

        {loading && <p className="text-center">Chargement des informations...</p>}
        
        {userInfo && (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Informations de l'utilisateur</h2>
            <p><span className="font-bold">Nom :</span> {userInfo.username}</p>
            <p><span className="font-bold">Email :</span> {userInfo.email}</p>
            <p><span className="font-bold">Adresse :</span> {userInfo.address || 'Non fourni'}</p>
            <p><span className="font-bold">Téléphone :</span> {userInfo.phone || 'Non fourni'}</p>
          </div>
        )}

        {error && <p className="text-red-500 mb-4">{error}</p>}
        
        {orders.length === 0 && !loading ? (
          <p>Aucune commande trouvée.</p>
        ) : (
          <>
            {/* Affichage vertical sur petits écrans */}
            <div className="block sm:hidden">
              {orders.map((order) => (
                (order.email === userInfo.email || order.user) && (
                  <div key={order._id} className="border-b border-gray-200 py-4">
                    <h3 className="font-semibold">
                      Commande ID: <Link to={`/order/${order._id}`} className="text-blue-500 hover:underline">{order.orderNumber}</Link>
                    </h3>
                    <p><span className="font-bold">Statut:</span> {order.status}</p>
                    <p><span className="font-bold">Date:</span> {new Date(order.createdAt).toLocaleDateString()}</p>
                  </div>
                )
              ))}
            </div>

            {/* Tableau sur grands écrans */}
            <div className="hidden sm:block overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="py-3 px-4 border-b text-left text-gray-600">Commande ID</th>
                    <th className="py-3 px-4 border-b text-left text-gray-600">Statut</th>
                    <th className="py-3 px-4 border-b text-left text-gray-600">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    (order.email === userInfo.email || order.user) && (
                      <tr key={order._id} className="hover:bg-gray-50">
                        <td className="py-3 px-4 border-b">
                          <Link to={`/order/${order._id}`} className="text-blue-500 hover:underline">
                            {order.orderNumber}
                          </Link>
                        </td>
                        <td className="py-3 px-4 border-b">{order.status}</td>
                        <td className="py-3 px-4 border-b">{new Date(order.createdAt).toLocaleDateString()}</td>
                      </tr>
                    )
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  </>
);

};

export default Orders;
