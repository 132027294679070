import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe("pk_test_51OKQmkGIYeLUOpCPWV7rrS1TPCUvwKmKXRS9dDo2LxTv1ELKmZm5PochcOS7eoAYMdBS7N7mV8zReRpHdcoFWohc00S4c0YW2n");

const Checkout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { price, orderDetails } = location.state;
    const [clientSecret, setClientSecret] = useState('');

    const createPaymentIntent = async () => {
        try {
            const amountInCents = Math.round(price * 100);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/create-payment-intent`, {
                order: { price: amountInCents },
            });

            setClientSecret(response.data.clientSecret);

        } catch (error) {
            console.error('Erreur lors de la création de l\'intent de paiement:', error);
        }
    };

    useEffect(() => {
        if (price > 0) {
            createPaymentIntent();
        } else {
            console.warn('Le prix est invalide:', price);
        }
    }, [price]);

    return (
        <div>
            {clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm
                        orderDetails={orderDetails}
                        navigate={navigate}
                    />
                </Elements>
            ) : (
                <p>Chargement du formulaire de paiement...</p>
            )}
        </div>
    );
};

export default Checkout;
