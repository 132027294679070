// src/pages/Legalisation.js
import React from 'react';
import Navbar from '../components/Navbar';
import ContactFormLegal from '../components/ContactFormLegal';


const Legalisation = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 p-6">
        <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-4">La Légalisation des Documents</h1>
          <p className="text-gray-600 mb-6">
            La légalisation des documents est une procédure essentielle pour garantir la validité de documents à l'étranger. Cela concerne principalement les documents administratifs, juridiques et d'état civil.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">1. Légalisation à la Mairie</h2>
          <p className="text-gray-600 mb-4">
            Pour les documents d'état civil, comme les actes de naissance ou de mariage, il est souvent nécessaire de se rendre à la mairie pour obtenir une légalisation. Ce processus assure que les documents sont conformes aux lois locales.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">2. Légalisation au Ministère des Affaires Étrangères</h2>
          <p className="text-gray-600 mb-4">
            Après avoir obtenu la légalisation de la mairie, certains documents doivent être présentés au Ministère des Affaires Étrangères. Ce dernier vérifie l'authenticité des signatures et des sceaux sur le document.
          </p>


          <h2 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">3. Légalisation pour les Émirats Arabes Unis</h2>
          <p className="text-gray-600 mb-4">
            Pour la légalisation des documents destinés aux Émirats, il est nécessaire de suivre un processus spécifique. Les documents doivent d'abord être légalisés par la mairie et le Ministère des Affaires Étrangères, puis être validés par l'ambassade ou le consulat des Émirats dans votre pays.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">Pourquoi est-ce important ?</h2>
          <p className="text-gray-600 mb-4">
            La légalisation assure que les documents seront acceptés par les autorités étrangères, évitant ainsi des complications lors de démarches administratives à l'étranger.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-4 mb-2">Contactez-nous</h2>
          <p className="text-gray-600 mb-4">
            Pour plus d'informations ou pour vous aider dans le processus de légalisation, n'hésitez pas à nous contacter via notre formulaire ou par téléphone.
          </p>
        <ContactFormLegal />

        </div>
      </div>

    </>
  );
};

export default Legalisation;
