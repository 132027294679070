
import React from 'react';
import { useLocation } from 'react-router-dom';

const OrderConfirmation = () => {
  const location = useLocation();
  const { orderNumber } = location.state || {};

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 p-6">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Confirmation de la commande</h1>
        {orderNumber ? (
          <p className="text-lg">Votre numéro de commande est : <strong>{orderNumber}</strong></p>
        ) : (
          <p className="text-lg text-red-500">Aucun numéro de commande disponible.</p>
        )}
      </div>
    </div>
  );
};

export default OrderConfirmation;

