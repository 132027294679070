import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';

const Navbar = () => {
  const location = useLocation();
  const { logout } = useAuth(); 

  const handleLogout = () => {
    Cookies.remove('token'); 
    logout(); 
    window.location.href = '/'; 
  };

  return (
    <nav className="pt-20 fixed left-0 top-0 h-full w-80 p-4 hidden lg:block"> 
      <div className="relative text-white text-left z-10">
        <ul className="space-y-4">
          <li>
            <Link to="/" className="text-xl font-bold hover:text-blue-500 transition duration-200">Accueil</Link>
          </li>
          {location.pathname !== '/orders' && (
            <li>
              <Link to="/orders" className="text-xl font-bold hover:text-blue-500 transition duration-200">Mes commandes</Link>
            </li>
          )}
          {location.pathname !== '/contact' && (
            <li>
              <Link to="/contact" className="text-xl font-bold hover:text-blue-500 transition duration-200">Contact</Link>
            </li>
          )}
          {location.pathname !== '/upload' && (
            <li>
              <Link to="/upload" className="text-xl font-bold hover:text-blue-500 transition duration-200">Envoyer une commande</Link>
            </li>
          )}
          {Cookies.get('token') ? ( 
            <li>
              <button 
                onClick={handleLogout} 
                className="text-xl font-bold hover:text-blue-500 transition duration-200"
              >
                Déconnexion
              </button>
            </li>
          ) : (
            <li>
              <Link to="/auth" className="text-xl font-bold hover:text-blue-500 transition duration-200">Connexion</Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
