import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Header = () => {
  const { isValidToken, logout } = useAuth();
  const [isVisible, setIsVisible] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const navigate = useNavigate();

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/auth');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsVisible(true); 
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg transition-transform duration-300 z-50
  ${isLargeScreen && !isVisible ? '-translate-y-full' : 'translate-y-0'}`}
    >
      <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
        {/* Logo Section */}
        <div className="text-lg font-semibold text-gray-800">
          <Link to="/" className="hover:text-slate-400 transition-colors duration-300">
            Service de Traduction
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
<button onClick={toggleMenu} className="focus:outline-none">
  {menuOpen ? (
    // Icône X (croix)
    <svg
      className="w-6 h-6 text-red-700"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  ) : (
    // Icône hamburger
    <svg
      className="w-6 h-6 text-gray-700"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6h16M4 12h16m-7 6h7"
      />
    </svg>
  )}
</button>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex space-x-8 items-center">
          <ul className="flex space-x-8 items-center">
            <li>
              <Link to="/" className="text-gray-700 hover:text-slate-400">Accueil</Link>
            </li>
            <li>
              <Link to="/contact" className="text-gray-700 hover:text-slate-400">Contact</Link>
            </li>
            <li>
              <Link to="/upload" className="text-gray-700 hover:text-slate-400">Envoyer une commande</Link>
            </li>
            <li>
              <Link to="/orders" className="text-gray-700 hover:text-slate-400">Mes Commandes</Link>
            </li>
            {isValidToken ? (
              <li>
                <button onClick={handleLogout} className="text-red-500 hover:text-red-800">
                  Déconnexion
                </button>
              </li>
            ) : (
              <li>
                <Link to="/auth" className="text-gray-700 hover:text-slate-400">Connexion / Inscription</Link>
              </li>
            )}
          </ul>
        </nav>

        {/* Mobile Menu */}
        {menuOpen && (
          <nav className="lg:hidden absolute top-full left-0 w-full bg-white shadow-lg z-40">
            <ul className="flex flex-col space-y-4 p-4">
              <li>
                <Link to="/" className="text-gray-700 hover:text-slate-400" onClick={() => setMenuOpen(false)}>Accueil</Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-700 hover:text-slate-400" onClick={() => setMenuOpen(false)}>Contact</Link>
              </li>
              <li>
                <Link to="/upload" className="text-gray-700 hover:text-slate-400" onClick={() => setMenuOpen(false)}>Envoyer une commande</Link>
              </li>
              <li>
                <Link to="/orders" className="text-gray-700 hover:text-slate-400" onClick={() => setMenuOpen(false)}>Mes Commandes</Link>
              </li>
              {isValidToken ? (
                <li>
                  <button onClick={() => { handleLogout(); setMenuOpen(false); }} className="text-red-500 hover:text-red-800">
                    Déconnexion
                  </button>
                </li>
              ) : (
                <li>
                  <Link to="/auth" className="text-gray-700 hover:text-slate-400" onClick={() => setMenuOpen(false)}>Connexion / Inscription</Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
