
import React from 'react';
import ContactForm from '../components/ContactForm';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-sky-900 via-blue-800 to-slate-600 p-6">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-4">Contactez-nous</h1>
          <p className="text-gray-600 text-center mb-6">
            Vous pouvez nous contacter via le formulaire ci-dessous :
          </p>
          <ContactForm />
          
          <p className="text-center mt-4">
            <Link to="/" className="text-blue-600 hover:underline">Retour à l'accueil</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
