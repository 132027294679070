import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';


const QuoteForm = () => {
  const location = useLocation();
  const service = location.state?.service;

  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (service) {
      const currentDate = new Date().toLocaleDateString('fr-FR');
      setTitle(`${service} - ${currentDate}`);
    }
  }, [service]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('message', message);
    formData.append('email', email);
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/quotes`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200 || response.status === 201) {
        setFeedback('Devis envoyé avec succès');
        setIsSubmitted(true);
        setTitle('');
        setEmail('');
        setMessage('');
        setFile(null);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setFeedback(error.response.data.message || "Vous avez atteint la limite de devis pour aujourd'hui.");
      } else {
        setFeedback('Erreur lors de l’envoi du devis');
      }
    }
  };

  if (isSubmitted) {
    return <div className="text-center text-green-500 font-bold">{feedback}</div>;
  }

  return (
    <>
          <Navbar />

    <div className="pt-5 pb-5 bg-gradient-to-r from-sky-900 via-gray-800 to-slate-600 min-h-screen flex items-center justify-center">
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white bg-opacity-90 rounded-lg shadow-lg p-8 space-y-6 mt-8 w-full">
        <h2 className="text-2xl font-semibold text-gray-800 text-center">Demande de Devis</h2>

        <div>
          <label htmlFor="title" className="block text-gray-700 font-medium">Objet</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            required
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-gray-700 font-medium">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
          />
        </div>

        <div>
          <label htmlFor="message" className="block text-gray-700 font-medium">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            rows="4"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="file" className="block text-gray-700 font-medium">Télécharger un fichier</label>
          <input
            type="file"
            id="file"
            onChange={handleFileChange}
            className="hidden"
          />
          
          <label
            htmlFor="file"
            className="w-full py-3 text-center text-white bg-blue-600 rounded-md cursor-pointer hover:bg-blue-500 transition duration-300 flex items-center justify-center"
          >
            {file ? file.name : 'Choisir un fichier'}
          </label>
        </div>

        <button
          type="submit"
          className="w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition duration-300 shadow-md transform hover:scale-105"
        >
          Envoyer le Devis
        </button>
        
        {feedback && <p className="text-center text-red-500">{feedback}</p>}
      </form>
    </div>
    </>
  );
  
};

export default QuoteForm;
