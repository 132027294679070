//AuthContext.js
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { isTokenValid } from '../utils/authUtils';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isValidToken, setIsValidToken] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token'); 
    const validationResult = isTokenValid(token);
    setIsValidToken(validationResult.isValid);
  }, []);

  const login = () => {
    const token = Cookies.get('token');
    if (token) {
      const validationResult = isTokenValid(token);
      setIsValidToken(validationResult.isValid);
    }
  };

  const logout = () => {
    Cookies.remove('token');
    setIsValidToken(false);
  };

  const value = useMemo(() => ({
    isValidToken,
    login,
    logout
  }), [isValidToken]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
